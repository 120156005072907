import * as React from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { ProductPackageProduct } from '@modules/offers/endless-holiday/models'
import { EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { EndlessHolidayProductsVariantsYear } from '@modules/offers/endless-holiday/details/products-selection/product-variant/product-variants-year'
import { ProductPackage } from '@models/app-state'

interface GroupedProducts {
  [year: string]: ProductPackageProduct[]
}

interface Props {
  productPackage: ProductPackage
}

export const EndlessHolidayProductsVariantsYears = ({ productPackage }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const accommodation = useWatch({ control, name: 'accommodation' })

  const groupedSubscriptionByYears: GroupedProducts = productPackage.products.reduce((acc, product) => {
    if (!product.stay_in_years || !product.stay_in_years.length || product.kind !== 'subscription') return acc

    const productYear = product.stay_in_years[0].toString()

    const appendProduct = (previous = []) => ({ ...acc, [productYear]: [...previous, product] })
    const isYearAlreadyInAcc = Object.keys(acc).includes(productYear)
    return isYearAlreadyInAcc ? appendProduct(acc[productYear]) : appendProduct()
  }, {})

  const gastroProducts = productPackage.products.filter(
    product => product.kind === 'gastro_card_product_package_endless_holiday',
  )

  const elementsToShow = Object.entries(groupedSubscriptionByYears).filter(([, products]) => {
    const selectedAccommodation = accommodation === 'house' ? 1 : 2
    return !!products.length && products.some(product => product.accommodation_type === selectedAccommodation)
  })

  return (
    <div className="w-100">
      {elementsToShow.map(([year, products]) => (
        <EndlessHolidayProductsVariantsYear
          key={year}
          year={year}
          products={products}
          gastroProducts={gastroProducts}
          priceSurplusWithoutGastro={productPackage.no_gastro_card_additional_fee}
        />
      ))}
    </div>
  )
}
