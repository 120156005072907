import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { GASTRO_CARD_BOOST_SLIDER_IMAGES } from '@modules/offers/gastro-card-boost/details'
import { BaseGastroCardView } from '@modules/offers/gastro-card/common/gastro-card-base-view'
import { GastroCardBlackWeekCostsInfo } from '@modules/offers/gastro-card/black-week/details/gastro-card-costs-info'
import { GastroCardWhereToUse } from '@modules/offers/gastro-card/common/gastro-card-where-to-use'

export const GastroCardChristmasView = (): React.ReactNode => (
  <BaseGastroCardView
    regularPriceFactor={2}
    subscriptionPriceFactor={2}
    kind="gastro_card_christmas_2024"
    ruleLinkTitle="Regulamin akcji Podwójna Radość Oferta Świąteczna"
    faqQuestions={FAQ_QUESTIONS}
    benefits={BENEFITS}
    images={GASTRO_CARD_BOOST_SLIDER_IMAGES}
    costInfo={<GastroCardBlackWeekCostsInfo offerName="Podwójna Radość Oferta Świąteczna" />}
    title="Podwójna Radość Oferta Świąteczna"
    description={
      <>
        <p className="fw-bold text-primary mb-2 font-xl">Zimowa edycja Podwójnej Radości w Holiday Park & Resort!</p>
        <p>
          Skorzystaj ze specjalnej promocji na <strong>Voucher Podwójna Oferta Świąteczna</strong> do wykorzystania we
          wszystkich punktach gastronomicznych (włącznie z Restauracją) podczas pobytów w terminie{' '}
          <span className="text-nowrap">1.01.2025 – 31.12.2026</span> w Resortach{' '}
          <span className="text-nowrap">Holiday Park & Resort.</span>
        </p>
        <p>
          Możesz nabyć voucher o wartości powiększonej o 100% wpłaconej kwoty. <br />
          <strong>
            Wpłacony {formatPriceShort(1000)} to {formatPriceShort(2000)} do wykorzystania!
          </strong>
        </p>
      </>
    }
    amountBoxTitle={
      <>
        Podwójna Radość <br /> Oferta Świąteczna
      </>
    }
  >
    <GastroCardWhereToUse withRestaurant />
  </BaseGastroCardView>
)

const BENEFITS = [
  'Wartość vouchera zostanie powiększona o 100% Twojej wpłaty',
  'Voucher możesz wykorzystać w otwartych punktach gastronomicznych (włącznie z Restauracją) na terenie Resortu',
  'Ilość voucherów, jakie możesz kupić jest nieograniczona',
  'Voucher jest wielokrotnego użytku (częściowo, w różnych rezerwacjach)',
]

const FAQ_QUESTIONS = [
  {
    question: 'W jakiej kwocie mogę nabyć voucher Podwójna Radość Oferta Świąteczna?',
    answer: 'Minimalna kwota zakupu to 500,00 zł. Górnej granicy nie ma. :)',
  },
  {
    question: 'Gdzie mogę skorzystać z vouchera?',
    answer: 'Voucher możesz wykorzystać we wszystkich otwartych punktach gastronomicznych na terenie Resortu.',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera?',
    answer: 'Twój voucher Podwójna Radość Oferta Świąteczna będzie ważny do końca 2026 roku.',
  },
  {
    question: 'Kiedy mogę skorzystać z vouchera?',
    answer: 'Voucher możesz wykorzystać podczas pobytów w terminie 01.01.2025 – 31.12.2026.',
  },
  {
    question: 'Jak zapłacić voucherem w punktach gastronomicznych?',
    answer:
      'Po złożeniu zamówienia w punkcie zbliż opaskę lub podaj numer swojego pokoju. W przypadku wyżywienia zgłoś chęć zapłaty voucherem w Recepcji Ośrodka. Zakupy automatycznie zostaną opłacone voucherem (w ramach dostępnych środków).',
  },
  {
    question: 'Czy muszę wykorzystać cały voucher od razu?',
    answer:
      'Nie, niewykorzystana kwota nie przepada. Voucher jest wielokrotnego użytku, możesz go przypisać do kolejnych rezerwacji (w terminie jego ważności).',
  },
  {
    question: 'Jak dodać voucher do rezerwacji?',
    answer:
      'Nic prostszego! Przypisz voucher w swoim Koncie Gościa. Znajdziesz go w kategorii produktów specjalnych. Możesz również to zrobić z pomocą Recepcji Ośrodka, podczas meldowania.',
  },
  {
    question: 'Czy mogę przypisać więcej niż jeden voucher do rezerwacji?',
    answer: 'Tak, natomiast kolejny voucher możesz przypisać po całkowitym wykorzystaniu tego poprzedniego.',
  },
]
