import * as React from 'react'
import { BaseGastroCardAmount } from '@modules/offers/common/base-gastro-card-amount/base-gastro-card-amount'
import { formatPriceShort } from '@helpers/price-helper'
import { GastroCardCode } from '@models/app-state'

interface Props {
  regularFactor: number
  subscriptionFactor: number
  title: React.ReactNode
  kind: GastroCardCode
}

export const GastroCardAmount = ({ regularFactor, subscriptionFactor, title, kind }: Props): React.ReactNode => (
  <BaseGastroCardAmount
    regularFactor={regularFactor}
    subscriptionFactor={subscriptionFactor}
    kindClassName={`is-${kind}`}
    sectionTitle={
      <span className="text-secondary">
        <strong>Dodaj</strong> swój <br /> <strong>voucher</strong>
      </span>
    }
    centerCardTitle="Otrzymujesz"
  >
    {({ amountToSpent }) => (
      <div className="text-center text-md-start">
        <span className="text-primary font-xxxl fw-semi-bold d-block">Twój voucher</span>
        <span className="font-xxxl text-white lh-sm fw-semi-bold d-block mt-3 pt-5">{title}</span>
        <section className="mt-5 mb-5 mb-lg-3 pb-3 pb-lg-0">
          <span className="text-primary fw-semi-bold font-xl">Wartość vouchera:</span>
          <span className="font-header fw-bold d-block text-white">{formatPriceShort(amountToSpent)}</span>
        </section>
      </div>
    )}
  </BaseGastroCardAmount>
)
