import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { Badge } from '@components/badge'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { useScrollToOffer } from '@hooks/use-scroll-to-offer'

const SECTION_ID = 'podwojna-radosc-black-week'

export const GastroCardBlackWeekOffer = (): React.ReactNode => {
  const { ref } = useScrollToOffer(SECTION_ID)
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.GastroCardBlackWeek)
  }

  return (
    <div className="container-lg" id={SECTION_ID} ref={ref}>
      <OfferSection
        onImageClick={handleShowDetails}
        badges={
          <div className="d-flex flex-wrap justify-content-center justify-content-sm-start">
            <Badge variant="red" text="Bestseller" className="mb-2 badge-pulsing col-12 col-sm-auto me-0 me-sm-3" />
            <Badge variant="gold" text="Oferta dostępna czasowo" className="mb-2 col-12 col-sm-auto" />
          </div>
        }
        title={
          <span className="font-header">
            Podwójna radość <br />
            Black Week
          </span>
        }
        description={
          <span>
            Skorzystaj ze specjalnej promocji{' '}
            <span className="text-nowrap">
              na <strong className="text-nowrap">Voucher Podwójna Radość Black Week</strong>
            </span>{' '}
            <span className="text-nowrap">do wykorzystania </span>
            <strong>we wszystkich punktach gastronomicznych</strong> podczas pobytów{' '}
            <span className="text-nowrap">w latach 2025-2026 w Resortach </span> <br />
            <span className="text-nowrap">Holiday Park & Resort</span>.
          </span>
        }
        onOrderClick={handleShowDetails}
        offerKind="gastro-card-black-week-2024"
        price={{
          pre: <div className="text-end d-block">Wielki powrót</div>,
          after: (
            <div className="fw-bold mt-2 lh-sm text-end font-xxl">
              ulubionej <br />
              promocji
            </div>
          ),
          shapePosition: 'bottom',
          className: 'font-sm pt-3',
        }}
      />
    </div>
  )
}
