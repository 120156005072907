import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { OfferDetailsAgreements } from '@modules/offers/common/details/personal-data/offer-details-agreements'
import { ChristmasVoucherManualBox } from '@modules/offers/christmas-voucher/details/christmas-voucher-manual-box'
import { useChristmasVoucher } from '@modules/offers/christmas-voucher/details/use-christmas-voucher'

export const ChristmasVoucherPersonalData = (): React.ReactNode => {
  const client = useAppSelector(selectClientDetails)
  const { productPackage } = useChristmasVoucher()

  return (
    <OfferDetailsPersonalData
      agreements={<OfferDetailsAgreements rulesUrl={productPackage?.urls.rules ?? ''} />}
      title={
        <span className="text-secondary">
          <strong className="d-block">Zamów</strong> swój voucher {client && `${client?.first_name}!`}
        </span>
      }
    >
      <ChristmasVoucherManualBox />
    </OfferDetailsPersonalData>
  )
}
