import * as React from 'react'
import { OfferSection } from '@modules/offers/offer-section'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { formatPriceShort } from '@helpers/price-helper'

export const CodesConversionOffer = (): JSX.Element => {
  const navigate = useNavigate()

  const handleShowDetails = () => {
    navigate(NavigationPath.CodeConversion)
  }

  return (
    <div className="container-lg">
      <OfferSection
        onImageClick={handleShowDetails}
        title={<span className="font-header">Konwersja i zmiana terminu wykorzystania kodu</span>}
        description="Zmień termin wykorzystania swojego kodu lub dokonaj jego konwersji i korzystaj ze swojego pobytu jeszcze wygodniej"
        onOrderClick={handleShowDetails}
        offerKind="codes-conversion"
        price={{
          pre: (
            <>
              <div>Od</div>
              <strong className="font-header-lg">{formatPriceShort(399)}</strong>
            </>
          ),
          after: 'jednorazowo',
          shapePosition: 'left-bottom',
        }}
      />
    </div>
  )
}
