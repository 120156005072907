import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { ACCOMMODATION_TYPES } from '@helpers/consts'
import { ProductPackageProduct } from '@modules/offers/endless-holiday/models'
import { ProductPackage } from '@models/app-state'

interface Response {
  productPackageApartmentProduct: ProductPackageProduct | null
  productPackageHouseProduct: ProductPackageProduct | null
  productPackage: ProductPackage | undefined
}

export const useChristmasVoucher = (): Response => {
  const appData = useAppSelector(selectAppData)

  const vouchers = appData.product_packages.filter(product => product.kind === 'voucher')

  const getVoucherForAccommodationType = (accommodationTypeIds: number[]) => {
    return vouchers.reduce((voucherProduct, productPackage) => {
      const voucher = productPackage.products.find(product => accommodationTypeIds.includes(product.accommodation_type))

      if (voucher) return voucher
      return voucherProduct
    }, null)
  }

  return {
    productPackageApartmentProduct: getVoucherForAccommodationType(ACCOMMODATION_TYPES.APARTMENTS),
    productPackageHouseProduct: getVoucherForAccommodationType(ACCOMMODATION_TYPES.HOUSES),
    productPackage: vouchers[0],
  }
}
