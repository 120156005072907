import * as React from 'react'
import { ExpandableProductDescription } from '@components/expandable-product-description'

export const ChristmasVoucherAdditionalCostsInfo = (): JSX.Element => (
  <section className="font-sm my-4">
    <ExpandableProductDescription isCollapsable>
      <p>
        Kup voucher na 3 dni (2 doby) pobytu w domku lub w apartamencie, a następnie skorzystaj z niego samodzielnie lub
        podaruj bliskim wyjątkowy prezent świąteczny.
      </p>

      <p>
        Pobyt z vouchera może odbyć się w terminie 01.01 – 31.05 oraz 01.10 – 31.12*, w ciągu 3 lat od daty zakupu.
        <div className="mt-1 small">*Z wyłączeniem terminów specjalnych.</div>
      </p>

      <p>
        Przy zakładaniu rezerwacji należy opłacić zabezpieczenie na poczet kaucji zwrotnej w wysokości 750 zł oraz
        jednorazową usługę przygotowania lokalu. Do każdej rezerwacji zostanie doliczona opłata eksploatacyjna i opłata
        klimatyczna, płatne podczas meldowania. Przy wyjeździe należy uiścić opłatę z tytułu zużytych mediów. Wysokość
        opłat została wskazana w Cenniku oraz w Regulaminie Głównym Holiday Park & Resort.
      </p>

      <p className="mb-0">Szczegółowe zasady wykorzystania określa Regulamin oferty.</p>
    </ExpandableProductDescription>
  </section>
)
