import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { IconWithText } from '@components/icon-with-text'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { GastroCardPersonalData } from '@modules/offers/gastro-card/common/gastro-card-personal-data'
import { GastroCardDynamicPayment } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-payment'
import { GastroCardDynamicCostsInfo } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-costs-info'
import { GastroCardWhereToUse } from '@modules/offers/gastro-card/common/gastro-card-where-to-use'
import { GastroCardDynamicAmount } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-amount'
import { GastroCardDynamicAgreements } from '@modules/offers/gastro-card/dynamic/details/gastro-card-dynamic-agreements'
import { useSelector } from 'react-redux'
import { selectAppState, selectClientDetails } from '@store/slices/app-slice'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'
import { useGastroCardDynamicCurrentOffers } from '@modules/offers/gastro-card/dynamic/details/use-gastro-dynamic-multipliers'
import { useNavigate } from 'react-router-dom'
import { NavigationPath } from '@models/routes'
import { GastroCardDynamicDescription } from '@modules/offers/gastro-card/dynamic/details/modals/offer-description/gastro-card-dynamic-description'

interface FormInputs {
  declared_sell_price: number
}

export const GastroCardDynamicView = (): JSX.Element => {
  const { isAppReady } = useSelector(selectAppState)

  const resortGalleryRef = React.useRef<HTMLDivElement>(null)
  const navigate = useNavigate()

  const bonusesTableRef = React.useRef<HTMLDivElement>(null)
  const client = useSelector(selectClientDetails)
  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const methods = useForm<FormInputs>({
    defaultValues: { declared_sell_price: 500 },
    mode: 'onChange',
  })

  const { offers } = useGastroCardDynamicCurrentOffers()

  React.useEffect(() => {
    if (!isAppReady) return
    if (!offers.length) navigate(NavigationPath.NotFound)
  }, [offers, isAppReady])

  const scrollTo = (ref: React.RefObject<HTMLElement>) => () => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Vouchery?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          title="Voucher Podwójna Radość 2025 Plus"
          description={<GastroCardDynamicDescription ref={bonusesTableRef} />}
        >
          <GastroCardDynamicCostsInfo benefits={BENEFITS} />
          <div className="d-flex flex-wrap mt-n2 gap-4">
            <OfferRuleLink
              keyword={
                hasActiveSubscription ? 'gastro_card_dynamic_for_subscription_rules' : 'gastro_card_dynamic_rules'
              }
              title="Regulamin akcji Podwójna Radość 2025 Plus"
            />

            <IconWithText
              text="Gdzie wykorzystasz swój voucher?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-darker-gray fw-semi-bold mt-3 mt-sm-0"
              onClick={scrollTo(resortGalleryRef)}
            />
          </div>
        </OfferDetailsBaseInfo>
        <GastroCardDynamicAmount onScrollToDiscountsTable={scrollTo(bonusesTableRef)} />
        <GastroCardWhereToUse />
        <GastroCardPersonalData agreements={<GastroCardDynamicAgreements />} />
        <GastroCardDynamicPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  'Wcześniejszy i większy zakup powoduje naliczenie większego bonusu',
  'Ilość voucherów, jakie możesz kupić jest nieograniczona',
  'Wartość vouchera może zostać powiększona o nawet 100% Twojej wpłaty',
  'Voucher możesz wykorzystać w otwartych punktach gastronomicznych na terenie Resortu',
  'Voucher jest wielokrotnego użytku (częściowo, w różnych rezerwacjach)',
  'Voucher jest ważny do końca 2025 roku',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/gastro-card/gallery/cafe.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/cafe.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/animations.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/animations.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/dessert.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/dessert.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/drinks.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/drinks.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/fruits.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/fruits.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card/gallery/bistro.webp'),
    thumbnail: require('@assets/images/offers/gastro-card/gallery/bistro.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'W jakiej kwocie mogę nabyć voucher Podwójna Radość 2025 Plus?',
    answer: 'Minimalna kwota zakupu to 500,00 zł. Górnej granicy nie ma. :)',
  },
  {
    question: 'Jak mogę zyskać większy bonus?',
    answer:
      'Im szybciej kupisz i więcej wpłacisz, tym większy bonus otrzymasz. Spiesz się! Poziom bonusu spada z każdym miesiącem. Dodatkowo, z aktywną subskrypcją otrzymasz zawsze +10%.',
  },
  {
    question: 'Gdzie mogę użyć voucher?',
    answer: 'Voucher możesz wykorzystać w Kawiarni, Bistro, Beach Barze i Słodkim Kąciku podczas pobytów w Resortach.',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera?',
    answer: 'Twój voucher będzie ważny do końca 2025 roku.',
  },
  {
    question: 'Kiedy mogę skorzystać z vouchera?',
    answer: 'Voucher Podwójna Radość 2025 Plus możesz wykorzystać podczas pobytów w terminie 01.01.2025 – 31.12.2025.',
  },
  {
    question: 'Jak zapłacić voucherem w punktach gastronomicznych?',
    answer:
      'Po złożeniu zamówienia w punkcie, zbliż opaskę lub podaj numer swojego pokoju. Zakupy automatycznie zostaną opłacone voucherem (w ramach dostępnych środków).',
  },
  {
    question: 'Czy muszę wykorzystać cały voucher od razu?',
    answer:
      'Nie, niewykorzystana kwota nie przepada. Voucher jest wielokrotnego użytku, możesz go przypisać do kolejnych rezerwacji (w terminie jego ważności).',
  },
  {
    question: 'Jak dodać voucher do rezerwacji?',
    answer:
      'Nic prostszego! Przypisz voucher w swoim Koncie Gościa. Znajdziesz go w kategorii produktów specjalnych. Możesz również to zrobić z pomocą Recepcji Ośrodka, podczas meldowania.',
  },
  {
    question: 'Czy mogę przypisać więcej niż 1 voucher Podwójna Radość 2025 Plus do rezerwacji?',
    answer: 'Tak, natomiast kolejny voucher możesz przypisać po całkowitym wykorzystaniu tego poprzedniego.',
  },
]
