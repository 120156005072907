import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { IconWithText } from '@components/icon-with-text'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { GastroCardBoostCostsInfo } from '@modules/offers/gastro-card-boost/details/gastro-card-boost-costs-info'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { formatPriceShort } from '@helpers/price-helper'
import { GastroCardBoostPersonalData } from '@modules/offers/gastro-card-boost/details/gastro-card-boost-personal-data'
import { GastroCardBoostPayment } from '@modules/offers/gastro-card-boost/details/gastro-card-boost-payment'
import { hasActiveSubscriptionContract } from '@modules/offers/gastro-card-boost/utils'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { GastroCardBoostAmount } from '@modules/offers/gastro-card-boost/details/gastro-card-boost-amount'

interface FormInputs {
  amountToSpent: number
  declared_sell_price: number
}

export const GastroCardBoostView = (): JSX.Element => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)
  const client = useAppSelector(selectClientDetails)

  const hasActiveSubscription = hasActiveSubscriptionContract(client)

  const methods = useForm<FormInputs>({
    defaultValues: { declared_sell_price: 1000, amountToSpent: 0 },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Vouchery?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={GASTRO_CARD_BOOST_SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Doładowanie smaku"
          description={
            <>
              <p>
                Skorzystaj ze specjalnej promocji na Voucher Doładowanie smaku do wykorzystania we wszystkich otwartych
                punktach gastronomicznych na terenie Resortu!
              </p>
              <p>
                Dzięki aktywnej Subskrypcji możesz nabyć voucher o wartości powiększonej o 50% wpłaconej kwoty. Wpłacony{' '}
                {formatPriceShort(1000)} to {formatPriceShort(1500)} do wykorzystania!
              </p>
              <p>Nie będąc Subskrybentem wartość vouchera zostanie zwiększona o 40% Twojej wpłaty.</p>
            </>
          }
        >
          <GastroCardBoostCostsInfo />
          <div className="d-flex flex-sm-row flex-column align-items-sm-center">
            <OfferRuleLink
              keyword={hasActiveSubscription ? 'gastro_card_boost_subscription_offer_rules' : 'gastro_card_boost_rules'}
              title="Regulamin akcji Doładowanie Smaku"
            />

            <IconWithText
              text="Gdzie wykorzystasz Doładowanie Smaku?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-darker-gray fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <GastroCardBoostAmount />
        <GastroCardBoostPersonalData />
        <GastroCardBoostPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  'Wartość vouchera zostanie powiększona o nawet 50% Twojej wpłaty',
  'Ilość voucherów, jakie możesz kupić jest nieograniczona',
  'Voucher możesz wykorzystać we wszystkich otwartych punktach gastronomicznych na terenie Resortu ',
  'Voucher jest wielokrotnego użytku (częściowo, w różnych rezerwacjach)',
]

export const GASTRO_CARD_BOOST_SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/meals.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/meals.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/delicious-food.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/delicious-food.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/pizza.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/pizza.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/drinks.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/drinks.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/deserts.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/deserts.webp'),
  },
  {
    original: require('@assets/images/offers/gastro-card-boost/gallery/coffe.webp'),
    thumbnail: require('@assets/images/offers/gastro-card-boost/gallery/coffe.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'W jakiej kwocie mogę nabyć voucher Doładowanie Smaku?',
    answer: 'Minimalna kwota zakupu to 500,00 zł. Górnej granicy nie ma. :) ',
  },
  {
    question: 'Gdzie mogę użyć voucher?',
    answer: 'Voucher możesz wykorzystać we wszystkich otwartych punktach gastronomicznych na terenie Resortu.',
  },
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego vouchera?',
    answer: 'Twój voucher Doładowanie Smaku będzie ważny do końca 2025 roku.',
  },
  {
    question: 'Kiedy mogę skorzystać z vouchera?',
    answer: 'Voucher możesz wykorzystać podczas pobytów w terminie 01.01.2024 – 31.12.2025.',
  },
  {
    question: 'Jak zapłacić voucherem w punktach gastronomicznych?',
    answer:
      'Po złożeniu zamówienia w punkcie, zbliż opaskę lub podaj numer swojego pokoju. W przypadku wyżywienia, zgłoś chęć zapłaty voucherem w Recepcji Ośrodka.\n' +
      'Zakupy automatycznie zostaną opłacone voucherem (w ramach dostępnych środków).',
  },
  {
    question: 'Czy muszę wykorzystać cały voucher od razu?',
    answer:
      'Nie, niewykorzystana kwota nie przepada. Voucher jest wielokrotnego użytku (częściowo), możesz go przypisać do kolejnych rezerwacji (w terminie do 31.12.2025).',
  },
  {
    question: 'Jak dodać voucher do rezerwacji?',
    answer:
      'Nic prostszego! Przypisz voucher w swoim Koncie Gościa. Znajdziesz go w kategorii produktów specjalnych.\n' +
      'Możesz również to zrobić z pomocą Recepcji Ośrodka, podczas meldowania.',
  },
  {
    question: 'Czy mogę przypisać więcej niż 1 voucher Doładowanie Smaku do rezerwacji? ',
    answer: 'Tak, natomiast kolejny voucher możesz przypisać po całkowitym wykorzystaniu tego poprzedniego.',
  },
]
