import { useAppSelector } from '@store/index'
import { selectAppData } from '@store/slices/app-slice'
import { GastroCardCode } from '@models/app-state'

interface Response {
  isAvailable: (code: GastroCardCode) => boolean
}

export const useGastroCardAvailability = (): Response => {
  const appData = useAppSelector(selectAppData)

  const isAvailable = (code: GastroCardCode) => appData.gastro_cards.some(gastroCard => gastroCard.code === code)

  return {
    isAvailable,
  }
}
