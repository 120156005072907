import * as React from 'react'
import { useAppSelector } from '@store/index'
import { selectClientDetails } from '@store/slices/app-slice'
import { OfferDetailsPersonalData } from '@modules/offers/common/details/personal-data/offer-details-personal-data'
import { GastroCardManualBox } from '@modules/offers/gastro-card/common/gastro-card-manual-box'

interface Props {
  agreements: React.ReactNode
}

export const GastroCardPersonalData = ({ agreements }: Props): JSX.Element => {
  const client = useAppSelector(selectClientDetails)

  return (
    <OfferDetailsPersonalData
      agreements={agreements}
      title={
        <span className="text-secondary">
          <strong className="d-block">Zamów</strong> swój voucher {client && `${client?.first_name}!`}
        </span>
      }
    >
      <GastroCardManualBox />
    </OfferDetailsPersonalData>
  )
}
