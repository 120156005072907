import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import clsx from 'clsx'

interface Props {
  withRestaurant?: boolean
}

export const GastroCardWhereToUse = ({ withRestaurant }: Props): React.ReactNode => (
  <div className="container-xl mx-auto">
    <OfferDetailsRow
      title={
        <span className="text-secondary">
          Gdzie wykorzystasz <br />
          <strong className="text-nowrap">swój voucher?</strong>
        </span>
      }
    >
      {withRestaurant && (
        <Item
          title="Restauracja"
          image={require('@assets/images/offers/gastro-card-dynamic/restaurant.webp')}
          className="col-12 mb-sm-5 mb-3"
          cardBorder="border border-primary"
          maxWidth="100%"
        />
      )}
      <div className="row gap-3 gap-sm-0">
        <Item title="Bistro" image={require('@assets/images/offers/gastro-card-dynamic/burger.webp')} />
        <Item title="Kawiarnia" image={require('@assets/images/offers/gastro-card-dynamic/caffe.webp')} />
        <Item title="Beach-Bar" image={require('@assets/images/offers/gastro-card-dynamic/drink.webp')} />
        <Item title="Słodki Kącik" image={require('@assets/images/offers/gastro-card-dynamic/waffle.webp')} />
      </div>
    </OfferDetailsRow>
  </div>
)

const Item = ({ title, image, className = 'col-md-3 col-sm-6 col-12', maxWidth = '400px', cardBorder = 'border' }) => (
  <div className={className}>
    <div className={clsx('rounded mx-auto', cardBorder)} style={{ maxWidth }}>
      <img src={image} alt={title} width="100%" height={85} style={{ maxHeight: 300 }} className="object-fit-cover" />
      <span className="fw-semi-bold text-center d-block my-2 font-xl">{title}</span>
    </div>
  </div>
)
