import * as React from 'react'
import { commonObjectPost } from '@requests/basic-requests'
import { useFormContext, useWatch } from 'react-hook-form'
import { BaseFormFields, OfferPaymentBar } from '@modules/offers/common/details/personal-data/offer-payment-bar'
import { useChristmasVoucher } from '@modules/offers/christmas-voucher/details/use-christmas-voucher'
import { asDecimal } from '@helpers/utils'

const RulesKeyword = 'product_package_voucher_rules'

export const ChristmasVoucherPayment = (): React.ReactNode => {
  const { control, getValues } = useFormContext()
  const { productPackageApartmentProduct, productPackageHouseProduct, productPackage } = useChristmasVoucher()

  const [houseAmount, apartmentAmount] = useWatch({ control, name: ['house_amount', 'apartment_amount'] })

  const apartmentPrice = asDecimal(productPackageApartmentProduct?.price_brutto || 0).mul(apartmentAmount)
  const housePrice = asDecimal(productPackageHouseProduct?.price_brutto || 0).mul(houseAmount)

  const totalPrice = apartmentPrice.add(housePrice).toString()

  const paymentAction = async (baseFields: BaseFormFields) => {
    const payload = getValues()

    return await commonObjectPost<{ urls: { sale: string } }>(productPackage?.urls.sale ?? '', {
      [RulesKeyword]: payload[RulesKeyword],
      products: [
        ...(houseAmount ? [{ product: productPackageHouseProduct?.id, amount: houseAmount }] : []),
        ...(apartmentAmount ? [{ product: productPackageApartmentProduct?.id, amount: apartmentAmount }] : []),
      ],
      ...baseFields,
    })
  }

  return (
    <OfferPaymentBar
      totalPrice={totalPrice}
      canSubmit={!!houseAmount || !!apartmentAmount}
      paymentAction={paymentAction}
      rulesKeywords={[RulesKeyword]}
    />
  )
}
