import * as React from 'react'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { IconWithText } from '@components/icon-with-text'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { FormProvider, useForm } from 'react-hook-form'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { GastroCardAmount } from '@modules/offers/gastro-card/common/gastro-card-amount'
import { GastroCardPersonalData } from '@modules/offers/gastro-card/common/gastro-card-personal-data'
import { GastroCardPayment } from '@modules/offers/gastro-card/common/gastro-card-payment'
import { GastroCardAgreements } from '@modules/offers/gastro-card/common/gastro-card-agreements'
import { AvailableRuleKeyword, GastroCardCode } from '@models/app-state'
import { FaqQuestion } from '@components/faq-section'
import { ReactImageGalleryItem } from 'react-image-gallery'

interface FormInputs {
  declared_sell_price: number
}

interface Props {
  kind: GastroCardCode
  ruleLinkTitle: string
  faqQuestions: FaqQuestion[]
  benefits: string[]
  images: ReactImageGalleryItem[]
  title: string
  description: React.ReactNode
  children?: React.ReactNode
  costInfo?: React.ReactNode
  regularPriceFactor: number
  subscriptionPriceFactor: number
  amountBoxTitle: React.ReactNode
}

export const BaseGastroCardView = ({
  kind,
  ruleLinkTitle,
  faqQuestions,
  benefits,
  images,
  title,
  description,
  children,
  costInfo,
  regularPriceFactor,
  subscriptionPriceFactor,
  amountBoxTitle,
}: Props): React.ReactNode => {
  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<FormInputs>({
    defaultValues: { declared_sell_price: 1000 },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <OfferDetailsLayout
      questions={faqQuestions}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Vouchery?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo images={images} benefits={benefits} title={title} description={description}>
          {costInfo}
          <div className="d-flex flex-wrap mt-n2 gap-4">
            <OfferRuleLink keyword={`${kind}_rules` as AvailableRuleKeyword} title={ruleLinkTitle} />
            <IconWithText
              text="Gdzie wykorzystasz swój voucher?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="font-lg lh-1"
              className="text-darker-gray fw-semi-bold mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <GastroCardAmount
          kind={kind}
          regularFactor={regularPriceFactor}
          subscriptionFactor={subscriptionPriceFactor}
          title={amountBoxTitle}
        />
        {children}
        <GastroCardPersonalData agreements={<GastroCardAgreements kind={kind} />} />
        <GastroCardPayment kind={kind} />
      </FormProvider>
    </OfferDetailsLayout>
  )
}
