import * as React from 'react'
import { EndlessHolidayFormInputs } from '@modules/offers/endless-holiday/details'
import { useFormContext, useWatch } from 'react-hook-form'
import { useEndlessHolidayTotalPrice } from '@modules/offers/endless-holiday/details/use-total-price'
import { formatPrice } from '@helpers/price-helper'
import { declinationProductsAmount } from '@modules/offers/endless-holiday/helpers'
import clsx from 'clsx'

interface Props {
  onScrollToSummary: () => void
}

export const EndlessHolidaySummaryStickyLabel = ({ onScrollToSummary }: Props): React.ReactNode => {
  const { control } = useFormContext<EndlessHolidayFormInputs>()
  const products = useWatch({ control, name: 'products' })

  const { totalPrice } = useEndlessHolidayTotalPrice(products)

  const productsAmount = React.useMemo(
    () =>
      products.reduce((count, product) => {
        if (product.product.kind === 'subscription') return count + product.amount
        return count
      }, 0),
    [products],
  )

  return (
    <div className={clsx('endless-holiday__summary__sticky-label__wrapper', { 'is-visible': !!products.length })}>
      <div className="endless-holiday__summary__sticky-label">
        <div className="p-1 text-white font-xs rounded-top position-relative">
          <div className="px-3 text-center pt-2 z-1 position-relative">
            <div className="endless-holiday__summary__sticky-label__amount">{productsAmount}</div>
            <div className="pt-4">{declinationProductsAmount(productsAmount)}</div>
            <hr className="mt-1 mb-2" />
            <span className="text-nowrap font-xxs">Do zapłaty</span>
            <br />
            <strong className="font-sm">{formatPrice(totalPrice)}</strong>
          </div>
          <button className="btn-unstyled endless-holiday__summary__sticky-label__button" onClick={onScrollToSummary}>
            Koszyk
          </button>
        </div>
      </div>
    </div>
  )
}
