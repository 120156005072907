import * as React from 'react'
import { IconWithText } from '@components/icon-with-text'
import { FormProvider, useForm } from 'react-hook-form'
import { OfferDetailsLayout } from '@layouts/offer-details-layout'
import { RESERVATION_APP_URLS } from '@helpers/consts'
import { OfferDetailsBaseInfo } from '@modules/offers/common/details/offer-details-base-info'
import { OfferRuleLink } from '@modules/offers/common/details/offer-rule-link'
import { useSubscriptionWithBenefits } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits'
import { useSubscriptionWithBenefitsGtmEvents } from '@modules/offers/subscription-with-benefit/details/use-subscription-with-benefits-gtm-events'
import { ChristmasVoucherAdditionalCostsInfo } from '@modules/offers/christmas-voucher/details/christmas-voucher-additional-costs-info'
import { ChristmasVoucherAccommodationVariants } from '@modules/offers/christmas-voucher/details/christmas-voucher-accommodation-variants'
import { ChristmasVoucherPayment } from '@modules/offers/christmas-voucher/details/christmas-voucher-payment'
import { ChristmasVoucherPersonalData } from '@modules/offers/christmas-voucher/details/christmas-voucher-personal-data'

interface FormInputs {
  email: string
  first_name: string
  last_name: string
  house_amount: number
  apartment_amount: number
  privacy_policy_rules: boolean
  subscription_with_benefit_rules: boolean
  main_rules: boolean
  sms_rules: boolean
  email_rules: boolean
}

export const ChristmasVoucherView = (): React.ReactNode => {
  const { sendViewItemListEvent } = useSubscriptionWithBenefitsGtmEvents()
  const { products } = useSubscriptionWithBenefits()

  const resortGalleryRef = React.useRef<HTMLDivElement>(null)

  const methods = useForm<FormInputs>({
    defaultValues: {
      house_amount: 0,
      apartment_amount: 0,
    },
    mode: 'onChange',
  })

  const handleScrollToResortsGallery = () => {
    resortGalleryRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  React.useEffect(() => {
    sendViewItemListEvent()
  }, [products])

  return (
    <OfferDetailsLayout
      questions={FAQ_QUESTIONS}
      ref={resortGalleryRef}
      resortGalleryTitle="Gdzie wykorzystać Pakiety Korzyści?"
    >
      <FormProvider {...methods}>
        <OfferDetailsBaseInfo
          images={SLIDER_IMAGES}
          benefits={BENEFITS}
          title="Świąteczne prezenty w Holiday"
          description={
            <>
              <strong>Podaruj bliskim wyjątkowy prezent na Święta!</strong> Wybierz voucher na 3-dniowy pobyt <br />
              <span className="text-nowrap">w Holiday Park & Resort</span> i wręcz coś więcej niż zwykły upominek.
            </>
          }
        >
          <ChristmasVoucherAdditionalCostsInfo />
          <div className="d-flex flex-sm-row flex-column align-items-sm-center">
            <OfferRuleLink keyword="product_package_voucher_rules" title="Regulamin akcji promocyjnej" />
            <IconWithText
              text="Gdzie wykorzystać vouchery?"
              iconClassName="uil-map-marker font-xxxl me-2 lh-1"
              textClassName="lh-1"
              className="text-darker-gray fw-semi-bold ms-sm-5 mt-3 mt-sm-0"
              onClick={handleScrollToResortsGallery}
            />
          </div>
        </OfferDetailsBaseInfo>
        <ChristmasVoucherAccommodationVariants />
        <ChristmasVoucherPersonalData />
        <ChristmasVoucherPayment />
      </FormProvider>
    </OfferDetailsLayout>
  )
}

const BENEFITS = [
  '7 resortów do wyboru ',
  '3 lata na wykorzystanie vouchera',
  'Pobyty na 3 dni w domku lub apartamencie (w zależności od wybranego wariantu)',
  'Możliwość zakupu dowolnej ilości voucherów',
]

const SLIDER_IMAGES = [
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/christmas.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/christmas.webp'),
  },
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/kinds-animations.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/kinds-animations.webp'),
  },
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/playground.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/playground.webp'),
  },
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/swimming-pool.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/swimming-pool.webp'),
  },
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/cafe.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/cafe.webp'),
  },
  {
    original: require('@assets/images/offers/christmas-voucher/gallery/water-slide.webp'),
    thumbnail: require('@assets/images/offers/christmas-voucher/gallery/water-slide.webp'),
  },
]

const FAQ_QUESTIONS = [
  {
    question: 'Ile mam czasu na wykorzystanie zakupionego Vouchera?',
    answer: 'Twój Voucher będzie ważny 3 lata od daty zakupu.',
  },
  {
    question: 'W jakich terminach można wykorzystać Voucher?',
    answer: (
      <span>
        Możesz do nas przyjechać od 01.01 do 31.05 oraz od 01.10 do 31.12*
        <br />
        <span className="font-sm">*z wyłączeniem terminów specjalnych </span>
      </span>
    ),
  },
  {
    question: 'Czym są terminy specjalne?',
    answer:
      'Są to terminy wyłączone z oferty, takie jak okres Świąt Wielkanocy i Bożego Narodzenia, Nowego Roku, Ferii zimowych czy Majówki lub weekendu Bożego Ciała. Szczegółowy wykaz terminów specjalnych znajdziesz w regulaminie oferty.',
  },
  {
    question: 'W których resortach mogę wykorzystać Voucher?',
    answer:
      'Masz aż wszystkie 7 resortów do wyboru. Dodatkowo w: Pobierowie, Rowach i Uzdrowisku Cieplice Zdrój czekamy na Ciebie przez cały rok!',
  },
  {
    question: 'Czy muszę założyć rezerwację od razu przy zakupie Vouchera?',
    answer: (
      <span>
        {' '}
        Nie. Nie wymagamy od Ciebie deklaracji terminu pobytu od razu. Otrzymasz Voucher{' '}
        <span className="text-nowrap">(kod promocyjny)</span>, który następnie użyjesz przy zakładaniu rezerwacji.
      </span>
    ),
  },
  {
    question: 'Jak mogę zapłacić za Voucher i ile mam czasu?',
    answer:
      'Korzystamy z bezpiecznych płatności Przelewy24. Sam wybierz wygodną dla siebie metodę płatności. Zapłać w ciągu 7 dni od złożenia zamówienia.',
  },
  {
    question: 'Kiedy otrzymam dostęp do zakupionego Vouchera?',
    answer:
      'Potwierdzenie e-mail wraz z listą kodów (voucherów pobytowych) otrzymasz od razu po zaksięgowaniu środków na naszym koncie bankowym. Cały proces zakupu może zająć zaledwie kilka minut!',
  },
  {
    question: 'Kto może skorzystać z zakupionego Vouchera?',
    answer:
      'Voucher możesz wykorzystać samodzielnie lub podarować go w prezencie. Przy zakładaniu rezerwacji będzie dokonana automatyczna aktywacja Vouchera na podane dane osoby rezerwującej.',
  },
  {
    question: 'Jak mogę założyć rezerwację? ',
    answer: (
      <section>
        Zachęcamy do skorzystania z wygodnego systemu rezerwacyjnego na naszej stronie internetowej pod adresem{' '}
        <a className="text-primary fw-bold text-decoration-none" href={RESERVATION_APP_URLS.MAIN_PAGE} target="_blank">
          rezerwuj.holidaypark.pl
        </a>{' '}
        lub przy pomocy <span className="text-nowrap">Biura Obsługi Klienta</span> pod numerem:{' '}
        <span className="text-nowrap">735 200 800</span>.
      </section>
    ),
  },
]
