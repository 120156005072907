import * as React from 'react'
import { EndlessHolidayAccommodationVariants } from '@modules/offers/endless-holiday/details/endless-holiday-accommodation-variants'
import { EndlessHolidayProductVariants } from '@modules/offers/endless-holiday/details/products-selection/endless-holiday-product-variants'

export const EndlessHolidaySelection = (): React.ReactNode => (
  <div className="container">
    <EndlessHolidayAccommodationVariants />
    <EndlessHolidayProductVariants />
  </div>
)
