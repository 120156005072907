import * as React from 'react'
import { OfferDetailsRow } from '@modules/offers/common/details/offer-details-row'
import { ChristmasVoucherAccommodationCard } from '@modules/offers/christmas-voucher/details/christmas-voucher-accommodation-card'
import { useFormContext, useWatch } from 'react-hook-form'
import { useChristmasVoucher } from '@modules/offers/christmas-voucher/details/use-christmas-voucher'

export const ChristmasVoucherAccommodationVariants = (): React.ReactNode => {
  const { productPackageHouseProduct, productPackageApartmentProduct } = useChristmasVoucher()
  const { setValue, control, getValues } = useFormContext()

  const [houseAmount, apartmentAmount] = useWatch({ control, name: ['house_amount', 'apartment_amount'] })

  const handleIncrement = (field: string) => () => {
    setValue(field, getValues(field) + 1)
  }

  const handleDecrement = (field: string) => () => {
    if (getValues(field) > 0) {
      setValue(field, getValues(field) - 1)
    }
  }

  return (
    <div className="container">
      <OfferDetailsRow
        title={
          <span className="text-secondary">
            <strong className="d-block">Wybierz</strong> typ lokalu
          </span>
        }
      >
        <div className="d-flex flex-wrap gap-4 flex-column flex-md-row">
          <ChristmasVoucherAccommodationCard
            onIncrement={handleIncrement('apartment_amount')}
            onDecrement={handleDecrement('apartment_amount')}
            amount={apartmentAmount}
            title="Apartament"
            price={productPackageApartmentProduct?.price_brutto ?? '0'}
            description="3 dni pobytu w 4-osobowym apartamencie"
            imageSrc={require('@assets/images/apartment-big.webp')}
          />

          <ChristmasVoucherAccommodationCard
            title="Domek"
            amount={houseAmount}
            onDecrement={handleDecrement('house_amount')}
            onIncrement={handleIncrement('house_amount')}
            price={productPackageHouseProduct?.price_brutto ?? '0'}
            description="3 dni pobytu w 9-osobowym domku"
            imageSrc={require('@assets/images/house-big.webp')}
          />
        </div>
      </OfferDetailsRow>
    </div>
  )
}
