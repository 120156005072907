import * as React from 'react'
import { formatPriceShort } from '@helpers/price-helper'
import { declinationVoucherAmount } from '@modules/offers/endless-holiday/helpers'
import { SpinInput } from '@components/spin-input'
import clsx from 'clsx'
import { ConfettiRef, ConfettiWrapper } from '@components/confetti-wrapper'

interface Props {
  imageSrc: string
  title: string
  description: string
  price: string
  onIncrement: () => void
  onDecrement: () => void
  amount: number
}

export const ChristmasVoucherAccommodationCard = ({
  imageSrc,
  title,
  description,
  price,
  onIncrement,
  onDecrement,
  amount,
}: Props): React.ReactNode => {
  const confettiRef = React.useRef<ConfettiRef>(null)

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    if (amount > 0) return

    confettiRef.current?.play()
    onIncrement()
  }
  return (
    <div
      className={clsx('bg-light-gray rounded col-md-5 col-12 position-relative', { 'cursor-pointer': amount === 0 })}
      onClick={handleClick}
      style={{ border: '2px solid #B6BEC5' }}
    >
      <ConfettiWrapper ref={confettiRef}>
        <div className="px-4 py-3">
          <div className="d-flex justify-content-between align-items-center mb-1">
            <strong className="font-xxl">{title}</strong>
            {amount > 0 && (
              <div className="badge-success font-xs text-nowrap">
                {amount} {declinationVoucherAmount(amount)}
              </div>
            )}
          </div>
          <span className="fw-semi-bold">{description}</span>
        </div>
        <img src={imageSrc} alt="Holiday Park & Resort" height={150} width="100%" className="object-fit-cover" />
        <div
          className="d-flex justify-content-between align-content-center px-4 py-3"
          style={{ borderTop: '2px solid #B6BEC5' }}
        >
          <div>
            <span className="fw-semi-bold">Cena za voucher</span>
            <br />
            <strong className="font-xxl text-primary">{formatPriceShort(price)}</strong>
          </div>
          <div>
            <SpinInput
              disabled
              onIncrement={amount > 0 ? onIncrement : undefined}
              onDecrement={onDecrement}
              min={0}
              value={amount}
            />
          </div>
        </div>
      </ConfettiWrapper>
    </div>
  )
}
